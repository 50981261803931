<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Prontuários</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="pacientes"
      :options.sync="options"
      :server-items-length="totalList"
      sort-by="nome"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat max-width="100%">
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div class="d-flex mt-3">
            <v-text-field
              v-model="searchId"
              label="Id do Pet"
              placeholder="Id do Pet"
              class="mr-5 col-4"
              append-icon="mdi-magnify"
              @click:append="searchPetById"
              @keydown.enter="searchPetById"
              single-line
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar por nome do pet"
              placeholder="Pesquisar por nome"
              single-line
              hide-details
            ></v-text-field>
          </div>

          <!-- DIALOG FOTOS CLASSIFICADAS -->
          <v-dialog
            v-model="dialogFotosClassificadas"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <fotos-classificadas
              :paciente="paciente.id_paciente"
              :key="rederComp"
              @close="close()"
            />
          </v-dialog>
          <!-- DIALOG DE PRONTUÁRIO -->
          <v-dialog
            v-model="dialog"
            v-if="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <prontuarios
              :consultas="prontuarios"
              :pacienteInfo="prontuariosInfo"
              @close="close()"
            />
          </v-dialog>

          <!-- DIALOG DE PRONTUÁRIO -->
          <v-dialog
            v-model="dialogGaleria"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <galerias :paciente="paciente" :key="rederComp" @close="close()" />
          </v-dialog>
          <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
            <v-card color="white">
              <v-card-text>
                Carregando...
                <v-progress-linear
                  indeterminate
                  color="green lighten-3"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogArquivo"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <arquivo :paciente="paciente" :key="rederComp" @close="close()" />
          </v-dialog>
          <v-dialog
            v-model="dialogUploadExames"
            v-if="dialogUploadExames"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <UploadExames
              :paciente="paciente"
              :key="rederComp"
              @close="dialogUploadExames = false"
            />
          </v-dialog>
          <v-dialog
            v-model="dialogAtestado"
            v-if="dialogAtestado"
            hide-overlay
            persistent
            width="30%"
          >
            <atestado @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogExames"
            v-if="dialogExames"
            hide-overlay
            persistent
            width="600"
          >
            <exames @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogEncaminhamento"
            v-if="dialogEncaminhamento"
            hide-overlay
            persistent
            width="55%"
          >
            <encaminhamento @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogReceita"
            v-if="dialogReceita"
            hide-overlay
            persistent
            width="60%"
          >
            <receita @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog v-model="dialogTermo" hide-overlay persistent width="40%">
            <termos @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogReceitaCarbonada"
            hide-overlay
            persistent
            width="50%"
          >
            <receita-carbonada @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog v-model="dialogAddAnaminese" persistent fullscreen>
            <v-card>
              <v-toolbar style="background-color: #1daf80; color: #fff">
                <v-btn icon dark @click="dialogAddAnaminese = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span class="headline">Anamnese Adicional</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <modal-nova-anaminese
                v-if="dialogAddAnaminese"
                :paciente="infoAnamnese"
                @close="(dialogAddAnaminese = false), teste()"
              />
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-if="true" v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          title="Declaração"
          @click="gerarAtestado(item)"
          >fas fa-file-medical
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Encaminhamento Médico"
          @click="gerarEncaminhamento(item)"
          >fas fa-user-md</v-icon
        >
        <v-icon
          small
          class="mr-2"
          title="Solicitar exames"
          @click="gerarSolicitacaoDeExame(item)"
          >fas fa-tasks
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Receita Novas"
          @click="gerarReceitasNovas(item)"
          >fas fa-capsules
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Receita Carbonada"
          @click="gerarReceitasCarbonada(item)"
          >fas fa-file-alt
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Termo de responsabilidade"
          @click="gerarTermos(item)"
          >fas fa-file-signature</v-icon
        >
        <v-icon
          size="19"
          class="mr-2"
          title="Upload de exames"
          @click="listUpload(item)"
          >mdi-file-upload-outline
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Galeria de fotos"
          @click="listGalerias(item)"
          >fas fa-images
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Fotos classificadas"
          @click="listFotosClassificadas(item)"
          >fa-solid fa-bookmark
        </v-icon>
        <v-icon small class="mr-2" title="Documentos" @click="listFile(item)"
          >fas fa-folder
        </v-icon>
        <v-icon
          small
          class="mr-2"
          title="Anamnese Adicional"
          v-if="perfilId != 1"
          @click="anamneseAdicional(item)"
          >fas fa-notes-medical
        </v-icon>
        <v-icon
          small
          class="mr-2"
          color="primary"
          title="Prontuários"
          @click="listProntuarios(item)"
          >fas fa-file-medical-alt
        </v-icon>
      </template>
    </v-data-table>

    <!-- EXAMES PDF -->
    <div id="examesPDF" ref="examesPDF" v-show="false">
      <div
        id="header"
        :class="
          htmlToPdf == 'receita' || 'receita-carbonada'
            ? 'header-receita d-flex'
            : 'd-flex'
        "
      >
        <img
          src="../assets/images/logo_spmv.png"
          style="margin: auto; width: 2.3rem; margin: 3px;"
          alt="Logo anclivepa público"
          v-if="informacoes_unidade.id == 29"
        />
        <img
          src="../assets/images/logo_televet.png"
          style="margin: auto; width: 2.3rem; margin: 3px;"
          alt="Logo anclivepa público"
          v-else-if="informacoes_unidade.id == 24"
        />
        <img
          src="../assets/images/anclivepa_publico.jpeg"
          style="margin: auto"
          alt="Logo anclivepa público"
          v-else
        />
      </div>
      <div id="main">
        <!-- DOC EXAMES -->
        <div class="d-flex flex-column w-100" v-if="htmlToPdf == 'exames'">
          <div
            class="d-flex justify-content-between align-items-stretch gap p-0"
          >
            <div>
              <b>Pet</b>
              <br />
              <b>Nome: </b> <span>{{ pet.nome_pet }}</span> <br />
              <b>Id: </b> <span>{{ pet.id_pet }}</span> <br />
              <b>Espécie: </b> <span>{{ pet.nome_especie }}</span> <br />
              <b>Raça: </b> <span>{{ pet.nome_raca }}</span> <br />
              <b>Sexo: </b> <span>{{ pet.sexo }}</span>
            </div>
            <div>
              <b>Tutor</b>
              <br />
              <b>Nome: </b> <span>{{ tutor.nome }}</span> <br />
              <b>CPF: </b> <span>{{ tutor.cpf }}</span> <br />
              <b>Endereço: </b>
              <span> {{ tutor.logradouro }}, {{ tutor.numero }} </span><br />
              <span> {{ tutor.cidade }} - {{ tutor.cep }} </span>
            </div>
          </div>
          <hr />
          <!-- <p>
            Tutor:<span class="space">_</span>{{ nome }}<br />
            Pet:<span class="space">_</span>{{ pet }}
          </p> -->
          <b>Solicito:</b>
          <u v-for="(exame, i) in exames" :key="i">
            <li class="text-nowrap resp">{{ exame }}</li>
          </u>
          <div class="break-w">
            <p>OBS.:<span class="space">_</span>{{ obs }}</p>
            <p v-if="obs2 != ''">OBS.:<span class="space">_</span>{{ obs2 }}</p>
            <p class="d-flex flex-column">
              <span class="ml-auto">
                _________________________________________</span
              >
              <span class="ml-auto">{{ usuarioNome }}</span>
            </p>
          </div>
        </div>
        <!-- DOC ATESTADO -->
        <div v-else-if="htmlToPdf == 'atestado'">
          <div v-if="tipoDeclaracao == 'atestado'">
            <p class="mt-1 text-center"><b>Atestado Médico</b></p>
            <p class="indent resp">
              Atesto para devidos fins que o(a) Sr(a). <b>{{ nome }}</b
              >, inscrito(a) no CPF sob o n° {{ cpf }}, compareceu à este
              Hospital Veterinário Faculdade Anclivepa - {{ unidade }} para
              realização de procedimentos veterinários, nesta data,
              {{
                data.getDate() +
                  "/" +
                  (data.getMonth() + 1) +
                  "/" +
                  data.getFullYear()
              }}.
            </p>
          </div>
          <div v-else-if="tipoDeclaracao == 'declaração de horas'">
            <p class="mt-1 text-center">
              <b>Declaração de Horas</b>
            </p>
            <p class="indent resp" v-if="preencherDadosTutor">
              Declaro, para os devidos fins, que o(a) Sr(a).
              {{ tutor.nome }}, portador do RG: {{ tutor.rg }} e CPF:
              {{ tutor.cpf }}, esteve acompanhando o pet {{ pet }}, em
              atendimento emergencial no Hospital Veterinário Anclivepa, na data
              ____ de _______________ de _______________, das ____:____ as
              ____:____.
            </p>
            <p class="indent resp" v-else>
              Declaro, para os devidos fins, que o(a) Sr(a).
              __________________________________________, portador do RG
              ______________________________ e CPF
              _____________________________, esteve acompanhando o pet
              ___________________________ em atendimento emergencial no Hospital
              Veterinário Anclivepa, na data ____ de _______________ de
              _______________, das ____:____ as ____:____.
            </p>
          </div>

          <div class="d-flex flex-column text-end pt-8">
            <p class="ml-auto">
              {{ informacoes_unidade.cidade }}, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p
              class="d-flex flex-column"
              style="margin: 0 !important; justify-content: center; align-items: center;"
            >
              <span> _________________________________________</span>
              <span>{{ unidade }}, CNPJ: {{ informacoes_unidade.cnpj }}</span>
            </p>
          </div>
          <div class="m-auto text-center rodape" style="width: 200px;">
            <!-- <span class="mr-1">{{ unidade }}</span> -->
            <span class="mr-1">
              {{
                informacoes_unidade.logradouro +
                  ", " +
                  informacoes_unidade.numero +
                  ", " +
                  informacoes_unidade.bairro +
                  ", " +
                  informacoes_unidade.cidade +
                  "/" +
                  informacoes_unidade.estado
              }}
              - {{ informacoes_unidade.cep }}
            </span>
            <span>{{ informacoes_unidade.telefone }}</span>
          </div>
        </div>
        <!-- DOC RECEITA -->
        <!-- <div
          class="d-flex flex-column w-100 resp"
          v-else-if="htmlToPdf == 'receita'"
          style="margin-bottom: 30px"
        >
          <div>
            <p>
              Tutor: <span class="space">_</span><b>{{ nome }}</b
              ><br />
              Pet: <span class="space">_</span><b>{{ pet }}</b>
            </p>
            <p class="mx-auto text-center mb-1"><b>MEDICAÇÕES</b></p>
          </div>
          <u v-for="(item, i) in receita" :key="i">
            <li class="d-flex flex-column resp">
              <span v-if="item.titulo">
                <strong
                  ><u>{{ item.titulo }}</u></strong
                > </span
              ><br />
              <p class="resp">
                {{ item.receitaTexto }}
              </p>
            </li>
          </u>
          <div class="d-flex flex-column text-end pt-8">
            <p class="ml-auto">
              {{ gerarData().dia }}<span class="space">_</span>de<span
                class="space"
                >_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
          </div>
          <p class="d-flex flex-column ml-auto">
            <span> _________________________________________</span>
            <span class="ml-auto">{{ usuarioNome }}</span>
          </p>
        </div> -->
        <!-- DOC RECEITA CARBONADA -->
        <div
          v-else-if="htmlToPdf == 'receita-carbonada' || htmlToPdf == 'receita'"
          class="carbon zeroEspaco color-black"
        >
          <!-- <div class="row row-cols-2" style="width: 18rem">
            <div class="col" style="border-right: solid gray 0.01px !important">
              <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
              <div class="row row-cols-2 resp">
                <div class="resp col col-8 pt-6">
                  <div
                    class="resp"
                    style="
                      border: solid gray 0.1px !important;
                      width: 4.5rem !important;
                      padding-right: 1px;
                      padding-left: 1px;
                    "
                  >
                    <span
                      class="ml-1 mt-2 pb-0 resp"
                      style="padding-top: 1px"
                    ></span
                    ><br />
                    <span class="ml-1 mt-2 pb-0 resp" style="padding-top: 1px"
                      >IDENTIFICAÇÃO DO EMITENTE</span
                    ><br />
                    <span class="text-decoration-underline resp p-0 mt-0"
                      >__________________________________________</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Cirurgia Plástica - CRM 6110 - RQE 2395</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >End.: Riomar Trade Center</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
                    >
                    <br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px">
                      10º andar - sala 1017</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Fone:(85) 99985.1522 / (85)3039.3890</span
                    ><br />
                  </div>
                </div>
                <div class="resp col col-4 p-0 pt-2">
                  1º via Farmácia <br />
                  2º via Farmácia <br /><br /><br /><br />
                  ___________________________ <br />
                  Carimbo e Assinatura <br />
                </div>
              </div>
            </div>
            <div class="col">
              <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
              <div class="row row-cols-2 resp receituario_controle">
                <div class="resp col col-8 pt-6">
                  <div
                    class="resp"
                    style="
                      border: solid gray 0.1px !important;
                      width: 4.5rem !important;
                      padding-right: 1px;
                      padding-left: 1px;
                    "
                  >
                    <span
                      class="ml-1 mt-2 pb-0 resp"
                      style="padding-top: 1px"
                    ></span
                    ><br />
                    <span class="ml-1 mt-2 pb-0 resp" style="padding-top: 1px"
                      >IDENTIFICAÇÃO DO EMITENTE</span
                    ><br />
                    <span class="text-decoration-underline resp p-0 mt-0"
                      >__________________________________________</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Cirurgia Plástica - CRM 6110 - RQE 2395</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >End.: Riomar Trade Center</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
                    >
                    <br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px">
                      10º andar - sala 1017</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size: 2.6px"
                      >Fone:(85) 99985.1522 / (85)3039.3890</span
                    ><br />
                  </div>
                </div>
                <div class="resp col col-4 p-0 pt-2">
                  1º via Farmácia <br />
                  2º via Farmácia <br /><br /><br /><br />
                  ___________________________ <br />
                  Carimbo e Assinatura <br />
                </div>
              </div>
            </div>
          </div>
          <div
            class="row rows-cols-2 pt-0"
            style="width: 18rem; padding-top: 0px"
          >
            <div
              class="col"
              style="
                border-right: solid gray 0.01px !important;
                padding-top: 0px;
              "
            >
              Paciente: <b>{{ nome }}</b
              ><br />
              Endereço: {{ logradouro }}, {{ numero }}. {{ complemento }} <br />
              <u v-for="(item, i) in receita" :key="i">
                <li class="d-flex flex-column resp">
                  {{ i + 1 }} - {{ item.medicamento }}_______{{ item.quantidade
                  }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
                </li> </u
              ><br /><br />
              <span class="resp pb-0 mb-0"
                >DATA: {{ new Date().toLocaleDateString() }}</span
              >
            </div>
            <div class="col" style="padding-top: 0px">
              Paciente: <b>{{ nome }}</b
              ><br />
              Endereço: {{ logradouro }}, {{ numero }}. {{ complemento }} <br />
              <u v-for="(item, i) in receita" :key="i">
                <li class="d-flex flex-column resp">
                  {{ i + 1 }} - {{ item.medicamento }}_______{{ item.quantidade
                  }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
                </li> </u
              ><br /><br />
              <span class="resp pb-0 mb-0"
                >DATA: {{ new Date().toLocaleDateString() }}</span
              >
            </div>
          </div>
          <div
            class="row rows-cols-2 pt-0 pr-0"
            style="width: 18rem; padding: 0px"
          >
            <div
              class="col"
              style="
                border-right: solid gray 0.01px !important;
                margin-left: 1px;
              "
            >
              <div
                class="row rows-cols-2"
                style="border: solid gray 0.01px !important"
              >
                <div
                  class="col pt-0"
                  style="border-right: solid gray 0.01px !important"
                >
                  <span>IDENTIFICAÇÃO DO COMPRADOR</span><br />
                  <br /><br /><br />
                  <span>Nome Completo_______________________</span><br />
                  <span>_______________________________________</span><br />
                  <span>IDENT:______________ORG.EMISSOR______</span><br />
                  <span>END:__________________________________</span><br />
                  <span>______________________________________</span><br />
                  <span>Cidade:______________UF:_______________</span><br />
                  <span>Tel:_____________________________</span><br />
                </div>
                <div class="col pt-0 pl-1" style="padding-right: 0px">
                  <span> IDENTIFICAÇÃO DO FORNECEDOR </span>
                  <br /><br /><br /><br /><br /><br /><br /><br />
                  <span>______________________________</span><br />
                  <span class="ml-1">Assinatura Farmacêutico</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="row rows-cols-2"
                style="
                  border-top: solid gray 0.01px !important;
                  border-right: solid gray 0.01px !important;
                  border-bottom: solid gray 0.01px !important;
                "
              >
                <div
                  class="col pt-0"
                  style="border-right: solid gray 0.01px !important"
                >
                  <span>IDENTIFICAÇÃO DO COMPRADOR</span><br />
                  <br /><br /><br />
                  <span>Nome Completo_______________________</span><br />
                  <span>_______________________________________</span><br />
                  <span>IDENT:______________ORG.EMISSOR______</span><br />
                  <span>END:__________________________________</span><br />
                  <span>______________________________________</span><br />
                  <span>Cidade:______________UF:_______________</span><br />
                  <span>Tel:_____________________________</span><br />
                </div>
                <div class="col pt-0 pl-1" style="padding-right: 0px">
                  <span> IDENTIFICAÇÃO DO FORNECEDOR </span>
                  <br /><br /><br /><br /><br /><br /><br /><br />
                  <span>______________________________</span><br />
                  <span class="ml-1">Assinatura Farmacêutico</span>
                </div>
              </div>
            </div>
          </div> -->
          <div class="body">
            <div class="d-flex justify-content-between align-items-center">
              <p>{{ unidade }}</p>
              <div class="text-right">
                <p>
                  {{ informacoes_unidade.logradouro }},
                  {{ informacoes_unidade.numero }} -
                  {{ informacoes_unidade.cidade }}
                </p>
                <p>CEP: {{ informacoes_unidade.cep }}</p>
                <p>Tel.: {{ informacoes_unidade.telefone }}</p>
                <p>CNPJ: {{ informacoes_unidade.cnpj }}</p>
              </div>
            </div>
            <hr />
            <div
              class="d-flex justify-content-between align-items-center m-0 mt-1 p-0 px-1"
              v-if="htmlToPdf == 'receita-carbonada'"
            >
              <p>Receita de Controle Especial</p>
              <p>1ª via: Farmácia | 2ª via: Paciente</p>
            </div>
            <div class="d-flex justify-content-between align-items-stretch gap">
              <div class="border p-1">
                <b>Identificação do Emitente</b><br />
                <br /><b>Nome: </b> <span>{{ usuarioNome }}</span> <br /><b
                  >CRMV: </b
                >{{ crmv }} <span></span> <br /><b>Endereço: </b>
                <span>
                  {{ informacoes_unidade.logradouro }},
                  {{ informacoes_unidade.numero }} </span
                ><br />
                <span>
                  {{ informacoes_unidade.cidade }} -
                  {{ informacoes_unidade.cep }}
                </span>
              </div>
              <div class="border p-1">
                <b>Animal</b><br />
                <br />
                <b>Nome: </b> <span>{{ pet.nome }}</span> <br />
                <b>Idade: </b> <span>{{ pet.idade }}</span> <br />
                <b>Espécie: </b> <span>{{ pet.especie }}</span> <br />
                <b>Raça: </b> <span>{{ pet.raca }}</span> <br />
                <b>Sexo: </b> <span>{{ pet.sexo }}</span> <br />
                <b>Peso: </b> <span>{{ pet.peso }}</span>
              </div>
              <div class="border p-1">
                <b>Tutor</b><br />
                <br />
                <b>Nome: </b> <span>{{ tutor.nome }}</span> <br />
                <b>CPF: </b> <span>{{ tutor.cpf }}</span> <br />
                <b>Endereço: </b>
                <span> {{ tutor.logradouro }}, {{ tutor.numero }} </span><br />
                <span> {{ tutor.cidade }} - {{ tutor.cep }} </span>
              </div>
            </div>
            <div v-if="htmlToPdf == 'receita-carbonada'">
              <u v-for="(item, i) in receita" :key="i">
                <li class="d-flex flex-column resp">
                  <div>
                    <b>{{ i + 1 }} - {{ item.medicamento }}</b>
                    <span> ( {{ item.quantidade }} )</span>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
                </li>
                <br />
              </u>
            </div>
            <div
              v-else
              id="intensReceitaTeste"
              class="ml-1 break-w"
              style="width: 94%;"
            >
              <!-- <u
                v-for="(item, i) in receita"
                :key="i"
                style="word-break:break-all"
              >
                <li class="d-flex flex-column zeroEspaco marg-2">
                  <span v-if="item.titulo">
                    <strong
                      ><u>{{ item.titulo }}</u></strong
                    > </span
                  ><br />
                  <p class="descMedicacao">
                    {{ item.receitaTexto }}
                  </p>
                </li>
              </u> -->
            </div>
          </div>
          <div>
            <br /><br />
            <hr />
            <div
              class="
                d-flex
                justify-content-between
                align-items-stretch
                gap
              "
            >
              <div>
                <p>
                  {{ informacoes_unidade.cidade }}, {{ gerarData().dia
                  }}<span class="space">_</span>de<span class="space">_</span
                  >{{ gerarData().mes }}<span class="space">_</span
                  >{{ gerarData().ano }}.
                </p>
                <p>
                  <b>{{ usuarioNome }}</b>
                </p>
                <p>CRMV: {{ crmv }}</p>
                <br />
                <p class="m-auto text-center">_________________________</p>
                <p class="m-auto text-center">Assinatura</p>
              </div>
              <div>
                <p><b>Identificação do Comprador</b></p>
                <p>Nome:</p>
                <p>RG:</p>
                <p>Endereço:</p>
                <p>Cidade e UF:</p>
                <p>Telefone:</p>
              </div>
              <div>
                <p><b>Identificação do Fornecedor</b></p>
                <p>Data:</p>
                <br /><br />
                <p class="m-auto text-center">_________________________</p>
                <p class="m-auto text-center">Assinatura</p>
              </div>
            </div>
          </div>
        </div>
        <!-- DOC TERMOS -->
        <div v-else-if="htmlToPdf == 'termo'" class="termo">
          <!-- CABEÇALHO -->
          <p class="text-center">
            <b v-if="tipoTermo == 'termo_um'"
              >TERMO DE RESPONSABILIDADE E RECUSA LIVRE E ESCLARECIDA PARA
              INTERNAÇÃO, EXAMES E/OU PROCEDIMENTOS CLÍNICOS, AMBULATORIAIS,
              ANESTÉSICOS, TERAPÊUTICOS E/OU CIRÚRGICOS</b
            >
            <b v-if="tipoTermo == 'termo_dois'"
              >TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA INTERNAÇÃO,
              EXAMES E/OU PROCEDIMENTOS CLÍNICOS, AMBULATORIAIS, ANESTÉSICOS,
              TERAPÊUTICOS E/OU CIRÚRGICOS</b
            >
            <b v-if="tipoTermo == 'termo_tres'"
              >TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA REALIZAÇÃO DE
              EUTANÁSIA</b
            >
            <b v-if="tipoTermo == 'termo_quatro'"
              >TERMO DE AUTORIZAÇÃO DE GRAVAÇÃO E/OU ENVIO DE IMAGEM E/OU
              ÁUDIO</b
            >
            <b v-if="tipoTermo == 'termo_cinco'"
              >TERMO LIVRE E ESCLARECIDO DE DOAÇÃO DE INSUMOS E PRODUTOS</b
            >
            <b v-if="tipoTermo == 'termo_seis'"
              >TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA DESTINAÇÃO DE
              CORPO DE ANIMAL EM ÓBITO
            </b>
            <b v-if="tipoTermo == 'termo_sete'">DECLARAÇÃO DE ÓBITO </b>
            <b v-if="tipoTermo == 'termo_oito'"
              >TERMO DE RESPONSABILIDADE PARA ANESTESIA</b
            >
            <b v-if="tipoTermo == 'termo_nove'"
              >TERMO DE ESCLARECIMENTO E RESPONSABILIDADE PARA RETIRADA DE
              ANIMAL
              <br />
              <span>DO SERVIÇO VETERINÁRIO SEM ALTA MÉDICA</span>
            </b>
          </p>

          <!-- CORPO -->
          <div>
            <div v-if="tipoTermo == 'termo_um'">
              <p class="small text-justify">
                Eu, {{ tutor.nome }}, portador(a) do CPF {{ tutor.cpf }}, com
                Cédula de Identidade RG/RNE {{ tutor.rg }}, residente e
                domiciliado(a) em {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}, detentor(a) do telefone
                n° {{ tutor.telefone_1 }} e do email {{ tutor.email }}, na
                condição de tutor(a) e responsável pelo animal de nome
                {{ pet.nome }}, espécie {{ pet.especie }}, sexo {{ pet.sexo }},
                declaro que NÃO autorizo a internação, tratamento clínico e/ou
                cirúrgico do Animal, cuja realização seria feita pelo
                {{ nomeHospitalConvenio }}.
                <!-- Médico(a) Veterinário(a) _____________________________, CRMV
                _________________________, no hospital gerido pela Uni-A
                Educação Ltda. -->
                <br />
                <br />
                Declaro ter sido cientificado(a) pelo(a) Médico(a)
                Veterinário(a) responsável sobre o quadro clínico do meu Animal
                e que há necessidade de realização do(s) seguinte(s) exame(s),
                procedimento(s) e/ou internação:<br />
                <span
                  >_________________________________________________________________________________</span
                ><br />
                <span
                  >_________________________________________________________________________________</span
                ><br />
                <br />
                Estou ciente de que o descumprimento da solicitação de exame(s)
                e/ou procedimento(s) clínico(s), ambulatorial(is),
                anestésico(s), terapêutico(s) e/ou cirúrgico(s) pode comprometer
                e/ou prejudicar a saúde e o quadro clínico do Animal, sua
                qualidade de vida e sua própria vida, sendo de minha total
                responsabilidade as consequências dessa decisão, não havendo o
                que possa reclamar em qualquer esfera administrativa e/ou
                jurídica contra o Médico(a) Veterinário(a) e/ou a Uni-A Educação
                Ltda.
                <br />
                <br />
                Assumo, ainda, ter sido esclarecido pelo(a) Médico(a)
                Veterinário(a) acerca dos possíveis riscos inerentes à situação
                clínica do Animal, bem como dos tratamentos propostos, estando
                o(a) referido(a) Médico(a) Veterinário(a) e a Uni-A Educação
                Ltda. isentos(as) de quaisquer responsabilidades decorrentes de
                tais riscos. <br />
                Autorizo o tratamento e processamento dos dados pessoais do
                tutor(a) responsável acima identificado, para as finalidades
                estabelecidas neste termo, e na forma prevista na legislação.
                <br />
                <br />Observações:
                ____________________________________________________________________
                <br />As informações contidas neste documento foram explicadas
                detalhadamente e em linguagem acessível e de fácil compreensão.
                Sendo assim, assino abaixo.
              </p>
            </div>
            <div v-if="tipoTermo == 'termo_dois'">
              <p class="small text-justify">
                Eu, {{ tutor.nome }}, portador(a) do CPF {{ tutor.cpf }}, com
                Cédula de Identidade RG/RNE {{ tutor.rg }}, residente e
                domiciliado(a) em {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}, detentor(a) do telefone
                n° {{ tutor.telefone_1 }} e do email {{ tutor.email }}, na
                condição de tutor(a) e responsável pelo animal de nome
                {{ pet.nome }}, espécie {{ pet.especie }}, sexo {{ pet.sexo }},
                cuja realização será feita pelo {{ nomeHospitalConvenio }}.
                <br />
                <br />
                Declaro, ainda, ter sido esclarecido sobre o(s) exame(s) e/ou
                procedimento(s) clínico(s), ambulatorial(is), anestésico(s),
                terapêutico(s) e/ou cirúrgico(s) indicado(s), os possíveis
                riscos inerentes à situação clínica do animal, bem como do(s)
                tratamento(s) proposto(s), estando o(a) Médico(a) Veterinário(a)
                e a Uni-A Educação Ltda. isento(a) de quaisquer
                responsabilidades decorrentes de tais riscos, estando ciente de
                todas as informações. Em caso de emergência, autorizo e dou
                autonomia ao Médico(a) Veterinário(a) para adotar todos os
                procedimentos destinados a restabelecer o quadro clínico do
                animal.<br />
                <br />
                Assumo a responsabilidade total pelos procedimentos
                pós-cirúrgicos, tais como: curativos, uso de colar elisabetano
                e/ou roupas cirúrgicas, comprometendo-me a comunicar os
                profissionais da equipe caso haja qualquer complicação e/ou
                alteração no quadro do Animal. Por fim, após o paciente ter
                recebido alta médica com a consecutiva comunicação do fato, fico
                ciente de que transcorrido 1 (um) dia e não havendo a retirada
                do Animal do estabelecimento dentro do aludido prazo, será
                considerado abandono, sendo o Animal encaminhado para adoção,
                sem o prejuízo das penas legais cabíveis. Com relação aos
                procedimentos pós-cirúrgicos, reconheço não haver o que se possa
                reclamar em qualquer esfera administrativa e/ou jurídica contra
                o Médico(a) Veterinário(a) e/ou a Uni-A Educação Ltda. Atesto,
                ainda, estar ciente de que a Uni-A Educação não se
                responsabiliza por itens pessoais ou do animal deixados na
                internação, e que fui orientado a não deixar tais itens junto ao
                animal no ambiente hospitalar.
                <br />
                <br />Observações:
                ____________________________________________________________________
                <br />
                Ordem de não reanimação: ( ) Sim ( ) Não
                <br />As informações contidas neste documento foram explicadas
                detalhadamente e em linguagem acessível e de fácil compreensão.
                Sendo assim, assino abaixo.
              </p>
            </div>
            <div v-if="tipoTermo == 'termo_tres'">
              <p class="small text-justify">
                Eu, {{ tutor.nome }}, portador(a) do CPF {{ tutor.cpf }}, com
                Cédula de Identidade RG/RNE {{ tutor.rg }}, residente e
                domiciliado(a) em {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}, detentor(a) do telefone
                n° {{ tutor.telefone_1 }} e do email {{ tutor.email }}, na
                condição de tutor(a) e responsável pelo animal de nome
                {{ pet.nome }}, espécie {{ pet.especie }}, sexo {{ pet.sexo }},
                autorizo o procedimento de eutanásia no Animal. <br />
                <br />Declaro estar ciente dos motivos que levam à realização da
                eutanásia no Animal acima identificado, reconhecendo que esta é
                a opção escolhida por mim, por livre e espontânea vontade, sem
                qualquer vício de consentimento, visando cessar definitivamente
                o sofrimento do referido Animal e, portanto, declaro o livre
                consentimento para a realização da eutanásia do Animal acima
                identificado, a ser realizado pelo
                {{ nomeHospitalConvenio }}.<br />
                <br />
                Declaro, ainda, que fui devidamente esclarecido(a) sobre o
                método a ser utilizado, assim como de que este é um procedimento
                irreversível, não havendo o que possa reclamar em qualquer
                esfera administrativa e/ou jurídica contra o Médico(a)
                Veterinário(a) e/ou a Uni-A Educação Ltda.<br />
                <br />
                <br />Observações:
                ____________________________________________________________________.
                <br />As informações contidas neste documento foram explicadas
                detalhadamente e em linguagem acessível e de fácil compreensão.
                Sendo assim, assino abaixo.
              </p>
            </div>
            <div v-if="tipoTermo == 'termo_quatro'">
              <p class="small text-justify">
                Eu, {{ tutor.nome }}, portador(a) do CPF {{ tutor.cpf }}, com
                Cédula de Identidade RG/RNE {{ tutor.rg }}, residente e
                domiciliado(a) em {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}, detentor(a) do telefone
                n° {{ tutor.telefone_1 }} e do email {{ tutor.email }}, na
                condição de tutor(a) e responsável pelo animal de nome
                {{ pet.nome }}, espécie {{ pet.especie }}, sexo {{ pet.sexo }},
                autorizo a gravação de imagem e/ou áudio do Animal, a ser
                realizada pelo {{ nomeHospitalConvenio }}. <br />
                <br />Assumo a responsabilidade por solicitar com no mínimo 8
                (oito) horas de antecedência, que sejam gravadas imagens
                diariamente, enquanto perdurar o período de internação. <br />
                <br />
                Em caso de solicitação prévia, declaro que fui esclarecido que o
                vídeo/áudio terá no máximo 2 (dois) minutos, e será enviado
                somente uma vez ao dia, no período entre 12:00 e 18:00,
                dependendo da disponibilidade da equipe da Uni-A Educação
                Ltda<br />
                <br />
                <br />Conforme os termos acima, autorizo o envio para o
                número:_______________________. <br />
                <br />
                Autorizo o tratamento e processamento dos dados pessoais do
                tutor(a) e responsávelacima identificado, para as finalidades
                estabelecidas neste termo e na forma prevista na legislação.
                <br />
                <br />As informações contidas neste documento foram explicadas
                detalhadamente e em linguagem acessível e de fácil compreensão.
                Sendo assim, assino abaixo.
              </p>
            </div>
            <div v-if="tipoTermo == 'termo_cinco'">
              <p class="text-justify">
                Eu, {{ tutor.nome }}, portador do RG {{ tutor.rg }}, CPF
                {{ tutor.cpf }}, firmo, na data do presente documento, a doação
                dos itens listados abaixo ao {{ nomeHospitalConvenio }}
                {{ unidade }}, CNPJ {{ informacoes_unidade.cnpj }}, localizado à
                {{ informacoes_unidade.logradouro }},
                {{ informacoes_unidade.numero }},
                {{ informacoes_unidade.cidade }} –
                {{ informacoes_unidade.cidade }}/{{
                  informacoes_unidade.estado
                }}, e reitero que o faço de livre e espontânea vontade, não
                requerendo contraparte, atual ou futura, por parte do tomador da
                doação.
              </p>
              <span><b>Itens:</b></span
              ><br /><br /><br />
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                </div>
                <div>
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                </div>
              </div>
            </div>
            <div v-if="tipoTermo == 'termo_seis'">
              <p class="small text-justify">
                Eu, {{ tutor.nome }}, portador do RG {{ tutor.rg }}, CPF
                {{ tutor.cpf }}, declaro, para os devidos fins, que nesta
                ocasião, autorizo a destinação do cadáver do animal
                {{ pet.nome }}, que teve seu óbito constatado na Associação
                Nacional de Clínicos Veterinários de Pequenos Animais São Paulo
                Anclivepa SP, às {{ data.getHours() }}:{{ data.getMinutes() }}h
                do dia {{ data.getDate() }}/{{ data.getMonth() }}/{{
                  data.getFullYear()
                }}, provocado pela provável causa mortis
                _____________________________, constando pelo(a) Médico(a)
                Veterinário(a) _____________________________, CRMV
                _________________________, e que fui informado(a) sobre a
                necessidade de dar tratamento respeitoso e destinação ambiental
                adequada ao cadáver, em respeito às normas ambientais vigentes.
                Assim sendo, autorizo:
                <br />
                <br />
                ( ) Remoção pela minha pessoa em até 24 (vinte e quatro) horas
                <br />
                ( ) Remoção pela pessoa de minha indicação em até 24 (vinte e
                quatro) horas, cujo nome e contato constam no campo de
                observações deste documento <br />
                ( ) Remoção por empresa especializada prestadora de serviços à
                Uni-A Educação <br />
                ( ) Remoção por funerária habilitada, sob minha escolha e
                autorização, cujo nome e contato constam no campo de observações
                deste documento <br />
                <br />
                Estou ciente de que que quando autorizar a remoção pela empresa
                especializada prestadora de serviços, ocorrerá a cremação
                coletiva e as cinzas do animal não serão devolvidas. Afirmo
                estar ciente da possibilidade de realização de necropsia e de
                que a Uni-A Educação Ltda. não possui este serviço no local,
                restando a mim a responsabilidade por destinar o corpo a
                instituição adequada para a execução de tal serviço. <br />
                <br />
                Autorizo que o corpo seja usado para fins educacionais ou
                científicos: ( ) Sim ( ) Não
                <br />
                <br />
                Observações:
                ___________________________________________________________.
                <br />
                As informações contidas neste documento foram explicadas
                detalhadamente e em linguagem acessível e de fácil compreensão.
                Sendo assim, assino abaixo
              </p>
            </div>
            <p v-if="tipoTermo == 'termo_sete'">
              Eu, ______________________________________, médico veterinário
              inscrito no CRMV _________________________, atesto, para os
              devidos fins, que o animal {{ pet.nome }}, do tutor responsável
              {{ tutor.nome }}, faleceu nas dependências da Uni-A Educação
              Ltda., na forma do {{ nomeHospitalConvenio }} {{ unidade }}, e com
              provável causa mortis _____________________________________.
            </p>
            <p v-if="tipoTermo == 'termo_oito'">
              Para fins de ordem legal, declaro que estou ciente dos riscos
              inerentes a qualquer prática anestésica, nada podendo reivindicar
              em qualquer momento, dando, assim, plenos poderes aos
              médicos-veterinários para fazê-lo com minha aquiescência.
              Igualmente, declaro as especificações do animal de minha
              propriedade, dato e assino o presente documento.
            </p>
            <p v-if="tipoTermo == 'termo_nove'" class="text-justify">
              Eu, {{ tutor.nome }}, portador(a) do CPF : {{ tutor.cpf }}, com
              Cédula de Identidade RG/RNE: {{ tutor.rg }}, residente e
              domiciliado(a) na {{ tutor.logradouro }}, {{ tutor.numero }} -
              {{ tutor.cidade }}/{{ tutor.estado }}, detentor(a) do telefone n°
              , {{ tutor.telefone_1 }}, {{ tutor.telefone_2 }} e do e-mail :
              {{ tutor.email }}, na condição de tutor(a) e responsável pelo
              animal de nome : {{ pet.nome }}, espécie: {{ pet.especie }}, raça
              : {{ pet.raca }}, sexo: {{ pet.sexo }}, pelagem : {{ pet.cor }}.
              Declaro sobre o quadro clínico do meu Animal e que este não se
              encontra apto para ser liberado da internação veterinária, sendo
              que não obteve alta médica e que há recomendação para manter o
              paciente em estabelecimento médico apropriado. Estou ciente que a
              retirada do Animal pode comprometer e prejudicar o seu tratamento,
              sua qualidade de vida e sua própria vida, sendo de minha total
              responsabilidade as consequências dessa decisão, não havendo o que
              possa reclamar em qualquer esfera administrativa e/ou jurídica
              contra o Médico(a) Veterinário(a) e/ou Hospital Veterinário .
              <br />
              <br />
              Assumo, ainda, ter sido esclarecido(a) e que estou ciente de que
              há riscos de agravamento da doença do paciente, inclusive morte, e
              acerca dos possíveis riscos ao Animal inerentes ao meu ato e que
              assumo inteira responsabilidade por esse ato, estando(a)
              referido(a) profissional e a Uni-A Educação Ltda. isentos(as) de
              quaisquer responsabilidades administrativas e/ou jurídicas
              decorrentes do feito.
              <br />
              <br />
              Autorizo o tratamento e processamento dos dados pessoais do acima
              identificado, para as finalidades estabelecidas neste termo, e na
              forma prevista na legislação.
              <br />
              <br />
              As informações contidas neste documento foram explicadas
              detalhadamente e em linguagem acessível e de fácil compreensão.
              Sendo assim, assino abaixo.
            </p>
          </div>
          <div
            v-if="tipoTermo == 'termo_cinco' || tipoTermo == 'termo_seis'"
            class="text-right"
          >
            <br /><span
              >{{ informacoes_unidade.cidade }},
              _______/_______/_______________</span
            ><br />
          </div>
          <div v-if="tipoTermo != 'termo_oito'" class="text-right">
            <span class="mr-1">_________________________, </span>
            <span>_______/_______/_______________</span><br />
          </div>
          <br />

          <!-- RODAPÉ -->
          <div
            v-if="(tipoTermo != 'termo_cinco') & (tipoTermo != 'termo_oito')"
          >
            <div class="m-auto text-center">
              <span>_____________________________________</span>
              <br />
              <span v-if="tipoTermo != 'termo_sete'"
                >Assinatura do(a) tutor(a) e/ou responsável</span
              >
              <span v-else
                >Assinatura e carimbo do(a) médico(a) veterinário(a)
                responsável</span
              >
              <br />
              <br />
              <span><b>Testemunhas</b></span
              ><br /><br />
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <span>Nome:</span><br />
                <span>CPF:</span><br />
                <span>Assinatura:</span><br />
              </div>
              <div>
                <span>Nome:</span><br />
                <span>CPF:</span><br />
                <span>Assinatura:</span><br />
              </div>
              <div class="w-2"></div>
            </div>
          </div>
          <div v-if="tipoTermo == 'termo_cinco'">
            <div class="m-auto text-center">
              <span>_____________________________________</span>
              <br />
              <span>Assinatura do doador</span>
              <br /><br /><br />
              <span>_____________________________________</span>
              <br />
              <span>Assinatura do receptor</span>
            </div>
          </div>
          <div v-if="tipoTermo == 'termo_oito'" class="mt-n2">
            <div class="">
              <p>
                {{ informacoes_unidade.cidade }}, {{ gerarData().dia
                }}<span class="space">_</span>de<span class="space">_</span
                >{{ gerarData().mes }}<span class="space">_</span
                >{{ gerarData().ano }}.
              </p>
            </div>
            <div>
              <span
                >Código: <b>{{ pet.petCodico }}</b></span
              ><br />
              <span
                >Espécie: <b>{{ pet.especie }}</b></span
              ><br />
              <span
                >Raça: <b>{{ pet.raca }}</b></span
              ><br />
              <span
                >Sexo: <b>{{ pet.sexo }}</b></span
              ><br />
              <span
                >Nome: <b>{{ pet.nome }}</b></span
              ><br />
              <span
                >Tutor: <b>{{ tutor.nome }}</b></span
              ><br />
              <span>
                Endereço:
                <b
                  >{{ tutor.logradouro }}, {{ tutor.numero }} -
                  {{ tutor.cidade }}/ {{ tutor.estado }}</b
                > </span
              ><br />
              <span
                >RG: <b>{{ tutor.rg }}</b></span
              ><br />
              <span
                >CPF: <b>{{ tutor.cpf }}</b></span
              >
            </div>
            <div class="text-center mt-4">
              <span>__________________________________________</span><br />
              <span>{{ tutor.nome }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-center rodape">
            <span class="mr-1">{{ unidade }}</span>
            <span class="mr-1">
              {{ informacoes_unidade.logradouro }},
              {{ informacoes_unidade.numero }},
              {{ informacoes_unidade.bairro }},
              {{ informacoes_unidade.cidade }}/{{
                informacoes_unidade.estado
              }}
              - {{ informacoes_unidade.cep }}
            </span>
            <span>{{ informacoes_unidade.telefone }}</span>
          </div>
          <!-- <p
            class="resp indent"
            style="font-size: 3px !important; margin-bottom: 20px"
            v-if="tipoTermo == 'termo_um'"
          >
            O (a) paciente ou responsável pelo paciente, Sr. (a)
            <b>{{ nome }}</b
            >, sob o CPF:{{ cpf }}, aqui declarado, dá plena autorização ao seu
            médico assistente, Dr. LUIZ NESTOR VASCONCELOS RODRIGUES C.R.M. Nº.
            6110, para fazer as investigações necessárias ao diagnóstico e
            executar tratamentos, operações, anestesias, transfusões de sangue,
            ou outras condutas médicas que venham de encontro às necessidades
            clínico-cirúrgicas do caso, bem como dá ciência de informações sobre
            sua capacidade técnico profissional e moral, comprometendo-se a
            respeitar as instruções que lhe forem fornecidas, inclusive quanto
            aos imprevistos oriundos da eventualidade como infecções, necroses
            de retalhos ou regiões operadas, assimetrias, deiscências, seromas,
            rejeições, no caso de próteses, embolia pulmonar, trombose venosa
            profunda, entre outras complicações inerentes aos procedimentos.
            Outrossim, sou consciente dos riscos informados, incluindo o de
            morte, ou da não observância das orientações médicas
            recomendadas.<br />
            CIRURGIA (S) PROPOSTAS (S): (indicação):<br />
            {{ procedimento }}
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_um'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>(Assinatura paciente ou responsável legal) </span>
            </p>
            <p class="d-flex flex-column mr-auto resp">
              NOME POR EXTENSO: {{ nome }}<br />
              RG. Ou CPF.: {{ cpf }}
            </p>
          </div>

          <p class="resp text-center" v-if="tipoTermo == 'termo_dois'">
            <b>TERMO DE CONSENTIMENTO INFORMADO EXPRESSO</b>
          </p>
          <p class="resp indent" v-if="tipoTermo == 'termo_dois'">
            Declaro que recebi o "TERMO DE INFORMAÇÕES PRÉ-CIRÚRGICAS" como
            também todos os esclarecimentos necessários sobre as informações
            específicas contidas neste termo sobre a cirurgia a ser realizada,
            onde foram explicados os detalhes sobre as cicatrizes resultantes,
            outros estados orgânicos, eventuais intercorrências, evoluções
            favoráveis e desfavoráveis pós-cirúrgicas, infecções, deiscências,
            necroses, assimetrias, perda da sensibilidade, rejeições, no caso de
            próteses, embolia pulmonar, trombose venosa profunda, inclusive
            risco de morte, entre outras complicações do procedimento bem como
            os cuidados para minimizar esses problemas. Assim, livremente e de
            espontânea vontade, assino (amos) o presente TERMO DE CONSENTIMENTO
            PÓS-INFORMADO para que o Dr. LUIZ NESTOR VASCONCELOS RODRIGUES,
            C.R.M. Nº. 6110 possa realizar o procedimento clínico-cirúrgico de e
            para que produza os devidos efeitos legais.
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_dois'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>Paciente</span>
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>(Responsável legal, qdo.menor)</span>
            </p>
          </div>
          <p class="resp text-center" v-if="tipoTermo == 'termo_tres'">
            <b>TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO</b>
          </p>
          <p class="resp indent" v-if="tipoTermo == 'termo_tres'">
            Eu <b>{{ nome }}</b
            >, Declaro para devidos fins que estou ciente de que realizarei
            procedimento cirúrgico em vigência da pandemia de infecção pelo
            COVID-19, com potencial risco de infecção, uma vez que a infecção é
            comunitária. Também estou ciente que o procedimento que serei
            submetida <b>{{ procedimento }}</b
            >, em riscos anestésico cirúrgicas, tendo potencial necessidade de
            internação em unidade de terapia intensiva (U.T.I.), que, no momento
            estão sendo utilizadas para pacientes portadores de COVID-19 (CORONA
            VÍRUS). Portando, estou ciente de que, em caso de internação em
            U.T.I., estarei em um ambiente sem isolamento e em contato próximo
            com pacientes com COVID -19. Tendo alto risco de contaminação por
            este vírus.
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_tres'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <div class="d-flex flex-row justify-space-between">
              <p class="d-flex flex-column mr-auto">
                <span> _____________________________________</span>
                <span class="ml-1">Assinatura Paciente/ Responsável</span><br />
                <span class="ml-1"> CPF: {{ cpf }}</span>
              </p>
              <p class="d-flex flex-column mr-auto">
                <span> _____________________________________</span>
                <span class="ml-3">Testemunha</span><br />
                CPF:______________________________
              </p>
            </div>
          </div> -->
        </div>
        <!-- DOC ENCAMINHAMENTO -->
        <div
          class="d-flex flex-column"
          v-else-if="htmlToPdf == 'encaminhamento'"
        >
          <!-- <p class="resp">{{ nomeMedico }}</p>
          <p class="resp">
            Encaminho o pet <b>{{ pet }}</b
            >, de tutor(a) <b>{{ nome }}</b
            >, para avaliação e conduta que julgar necessária.
          </p>
          <p class="ml-auto">Agradeço a atenção.</p> -->
          <div id="corpoEncaminhamento"></div>
          <div class="d-flex flex-column text-end pt-8">
            <p class="ml-auto">
              {{ informacoes_unidade.cidade }}, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column ml-auto">
              <span> _________________________________________</span>
              <span class="ml-auto resp">{{ usuarioNome }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- <div id="footer" v-if="htmlToPdf != 'receita-carbonada'">
        <img src="@/assets/images/footer.jpg" alt="" />
      </div> -->
    </div>
  </div>
</template>

<script>
import Atestado from "@/components/documentos/atestado.vue";
import Encaminhamento from "@/components/documentos/encaminhamento.vue";
import Exames from "@/components/documentos/exames.vue";
import Receita from "@/components/documentos/receita.vue";
import ReceitaCarbonada from "@/components/documentos/receita_carbonada.vue";
import Termos from "@/components/documentos/termos.vue";
import Galerias from "@/components/prontuarios/galeria.vue";
import Arquivo from "@/components/prontuarios/arquivos.vue";
import UploadExames from "@/components/prontuarios/uploadExames.vue";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import ModalNovaAnaminese from "@/components/recepcao_medico/infoChamada/nova_anaminese";
import ProfissionalService from "@/services/profissional_service.js";
// import ConsultaService from "@/services/consulta_service.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FotosClassificadas from "@/components/prontuarios/fotos_classificadas.vue";
import PetsService from "../services/pets_service.js";
import PacienteService from "../services/paciente_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  components: {
    Prontuarios,
    Galerias,
    Arquivo,
    Atestado,
    Exames,
    Encaminhamento,
    Receita,
    ReceitaCarbonada,
    Termos,
    FotosClassificadas,
    UploadExames,
    ModalNovaAnaminese,
  },
  beforeMount() {
    if (this.$route.query.search) {
      this.search = this.$route.params.search;
    }
    if (this.$router.params.paciente) {
      this.buscarEspecifico = true;
    }
  },

  data: () => ({
    usuarioNome: "",
    unidade: "",
    rederComp: 0,
    dialog: false,
    loading: false,
    dialogLoading: false,
    search: "",
    searchId: "",
    totalList: 10,
    buscarEspecifico: false,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Id Pet",
        align: "start",
        sortable: true,
        value: "id_pet",
      },
      { text: "Pet", value: "nome_pet" },
      {
        text: "Tutor",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    dialogCadastro: false,
    dialogGaleria: false,
    dialogFotosClassificadas: false,
    dialogUploadExames: false,
    dialogAddAnaminese: false,
    paciente: {},
    visualizar: false,
    pacientes: [],
    prontuarios: [],
    htmlToPdf: "",
    // Documentos
    arquivos: [],
    dialogArquivo: false,
    crmv: "",
    // Atestado
    dialogAtestado: false,
    nome: "",
    pet: "",
    tipoDeclaracao: "",
    //Encaminhamento
    dialogEncaminhamento: false,
    nomeMedico: "",
    //Solicitar Exame
    dialogExames: false,
    exames: [],
    obs: "",
    obs2: "",
    //Receita Nova
    dialogReceita: false,
    receita: "",
    //Receita Carbonada
    dialogReceitaCarbonada: false,
    tutor: {},
    logradouro: "",
    numero: "",
    bairro: "",
    cidade: "",
    cep: "",
    telefone: "",
    //Termos
    dialogTermo: false,
    tipoTermo: "",
    procedimento: "",
    cpf: "",
    pacienteService: "",
    petService: PetsService.build(),
    convenioPublico: true,
    //Prontuarios
    prontuariosInfo: "",
    preencherDadosTutor: false,
    infoAnamnese: {
      TutorCodigo: "",
      PetCodigo: "",
    },
    perfilId: JSON.parse(sessionStorage.vuex).perfil.id,
  }),

  computed: {
    nomeHospitalConvenio() {
      return this.convenioPublico
        ? "Hospital Veterinário Público Anclivepa"
        : "Hospital Veterinário Faculdade Anclivepa";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarListaPacientes();
      }
      if (value.length == 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarListaPacientes();
      }
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarListaPacientes();
      },
      deep: true,
    },
  },

  mounted() {
    this.usuarioNome = JSON.parse(
      sessionStorage.getItem("vuex")
    ).usuario.dados.nome;
    this.unidade = JSON.parse(sessionStorage.getItem("vuex")).unidade.nome;
    this.unidade = this.unidade.toUpperCase();
    this.crmv = JSON.parse(sessionStorage.getItem("vuex")).perfil.documento;
    this.informacoes_unidade = JSON.parse(
      sessionStorage.getItem("vuex")
    ).unidade;
    this.data = new Date();
    this.pacienteService = new PacienteService();
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
      this.atualizarListaPacientes();
    }
    this.atualizarListaPacientes();
  },
  methods: {
    forceRender() {
      this.rederComp += 1;
    },
    atualizarListaPacientes(filterId) {
      const profissionalService = new ProfissionalService();
      this.loading = true;
      this.pacientes = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = body.data.pagination.num_rows;

          this.pacientes = body.data.pets.map((paciente) => {
            return paciente;
          });

          if (this.buscarEspecifico) {
            this.pacientes = this.pets.filter(
              (paciente) => paciente.id == this.$router.params.paciente.id
            );
          }
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      // this.search = this.buscarEspecifico
      //   ? this.$router.params.paciente.nome
      //   : this.search;

      profissionalService.getPacienteList(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        filterId ? this.searchId : this.search,
        filterId,
        this.options.ordenacao,
        this.options.desc
      );

      if (this.buscarEspecifico) {
        this.buscarEspecifico = false;
      }
    },
    getTutor(idPaciente) {
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.tutor = body.data;
          console.log(this.tutor);
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {};
      this.pacienteService.get({ onSucess, onError, onEnd }, idPaciente);
    },
    async gerarAtestado(item) {
      this.htmlToPdf = "";
      console.log(item);
      await this.getTutor(item.id_paciente);
      this.pet = item.nome_pet;
      this.nome = item.nome;
      this.cpf = item.cpf;
      this.dialogAtestado = true;
    },
    gerarEncaminhamento(item) {
      this.htmlToPdf = "";
      this.pet = item.nome_pet;
      this.nome = item.nome;
      this.dialogEncaminhamento = true;
      this.htmlToPdf = "encaminhamento";
    },
    async gerarSolicitacaoDeExame(item) {
      console.log(item);
      await this.getTutor(item.id_paciente);
      this.pet = item;
      this.htmlToPdf = "";
      // this.pet = item.nome_pet;
      // this.nome = item.nome;
      this.dialogExames = true;
    },
    async gerarReceitasNovas(item) {
      this.htmlToPdf = "";
      await this.getTutor(item.id_paciente);
      this.pet = {
        nome: item.nome_pet,
        idade: this.calcularIdade(item.data_nascimento),
        especie: item.nome_especie,
        raca: item.nome_raca,
        sexo: item.sexo == "M" ? "macho" : "fêmea",
        peso: item.peso || "Não cadastrado",
      };
      this.nome = item.nome;
      this.htmlToPdf = "receita";
      this.dialogReceita = true;
    },
    calcularIdade(dataNascimento) {
      if (!dataNascimento) {
        return 0;
      }
      const dataAtual = new Date();
      const dataNascimentoPet = new Date(dataNascimento);

      let anos = dataAtual.getFullYear() - dataNascimentoPet.getFullYear();
      let meses =
        anos * 12 + (dataAtual.getMonth() - dataNascimentoPet.getMonth());

      if (meses < 12) {
        return `${meses} meses`;
      } else {
        return `${Math.floor(meses / 12)} anos e ${meses % 12} meses`;
      }
    },
    async gerarReceitasCarbonada(item) {
      console.log(item);
      await this.getTutor(item.id_paciente);
      this.pet = {
        nome: item.nome_pet,
        especie: item.nome_especie,
        raca: item.nome_raca,
        sexo: item.sexo == "M" ? "macho" : "fêmea",
        peso: item.peso || "Não cadastrado",
      };
      this.nome = item.nome;
      this.htmlToPdf = "";
      this.dialogReceitaCarbonada = true;
    },
    async gerarTermos(item) {
      this.htmlToPdf = "";
      await this.getTutor(item.id_paciente);
      console.log(item);
      this.pet = {
        nome: item.nome_pet,
        especie: item.nome_especie,
        raca: item.nome_raca,
        sexo: item.sexo == "M" ? "Macho" : "Fêmea",
        peso: item.peso || "Não cadastrado",
        petCodico: item.id_pet,
        cor: item.cor,
      };
      this.nome = item.nome;
      this.cpf = item.cpf;
      this.dialogTermo = true;
      setTimeout(() => {
        console.log(this.tutor);
      }, 1000);
    },
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      let dataFormatada = {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
      return dataFormatada;
    },

    gerarPDF(info) {
      let content = "";
      this.convenioPublico = info.convenio == "publico" ? true : false;
      switch (info.doc) {
        case "atestado":
          if (info.tipo == "declaração de horas") {
            this.preencherDadosTutor = info.preencherDadosTutor;
          }
          this.htmlToPdf = info.doc;
          this.tipoDeclaracao = info.tipo;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Declaração",
              "padrao"
            );
          });
          break;
        case "termo":
          this.htmlToPdf = info.doc;
          this.tipoTermo = info.termo_tipo;
          this.procedimento = info.procedimento;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Termo de Responsabilidade",
              "padrao"
            );
          });
          break;
        case "receita":
          // this.htmlToPdf = info.doc;
          document.getElementById("intensReceitaTeste").innerHTML =
            info.receita;
          this.receitas = document.getElementById(
            "intensReceitaTeste"
          ).children;
          this.receitas.forEach((item) => {
            // item.innerText = item.innerText.replaceAll("_", ".");
            console.log(item.innerText);
            item.style.cssText =
              "margin: 3px; font-size: 4px; width: 97%; font-family: Times, Times New Roman, serif;";
          });
          // this.receita = info.receita;

          this.$nextTick(() => {
            this.close();
            this.criarPDF(this.$refs.examesPDF.innerHTML, "Receita", "padrao");
          });
          break;
        case "receita-carbonada":
          console.log(info.doc);
          this.htmlToPdf = info.doc;
          this.receita = info.receita;

          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Receita-carbonada",
              "padrao"
            );
          });
          break;
        case "encaminhamento":
          // this.nomeMedico = info.nomeMedico;
          content = info.content.replaceAll(
            'class="ql-align-right"',
            'style="text-align: end;"'
          );
          content = info.content.replaceAll(
            "<p>",
            '<p style="margin-top: -10px;">'
          );

          document.getElementById("corpoEncaminhamento").innerHTML = content;
          document.getElementById("corpoEncaminhamento").style.cssText =
            "font-family: Times, Times New Roman, serif; line-height: 75%; margin-top: 10px";

          console.log(document.getElementById("corpoEncaminhamento").innerHTML);
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Encaminhamento",
              "padrao"
            );
          });
          break;
        case "exames":
          this.htmlToPdf = info.doc;
          this.exames = info.exames;
          this.obs = info.obs;
          this.obs2 = info.obs2;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Solicitação de exames",
              "padrao"
            );
          });
          break;

        default:
          console.log("Nenhum tipo de documento encontrado");
          break;
      }
    },
    criarPDF(content, nome, tipo) {
      console.log(this.htmlToPdf);
      console.log(content);
      window.html2canvas = html2canvas;
      if (tipo == "padrao") {
        var doc = new jsPDF("p", "mm", "a4");
        doc.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 5,
          y: 5,
        });
      } else {
        var docm = new jsPDF("l", "mm", "a4");
        docm.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 1,
          y: 1,
        });
      }
    },

    async listProntuarios(item) {
      this.paciente = item;
      this.dialogLoading = true;
      await this.petService
        .getConsultasbyUnidade(
          item.id_pet,
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        // .getConsultas(item.id_pet)
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: data.message,
              color: response.status === 200 ? "sucess" : "error",
            });
          }
          console.log(data);
          this.prontuarios = data.data.consultas;
          this.prontuariosInfo = item;
          this.dialog = true;
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    anamneseAdicional(item) {
      if (item.id_cliente != 0 && item.id_pet != 0) {
        this.infoAnamnese.TutorCodigo = item.id_cliente;
        this.infoAnamnese.PetCodigo = item.id_pet;
        this.infoAnamnese.NomeTutor = item.nome;
        this.infoAnamnese.NomePet = item.nome_pet;
        this.dialogAddAnaminese = true;
      } else {
        this.dialogAddAnaminese = false;
      }
    },
    teste() {
      console.log("Teste aqui");
    },
    listFile(item) {
      this.paciente = item;
      this.dialogArquivo = true;
      this.forceRender();
    },

    listUpload(item) {
      this.paciente = item;
      this.dialogUploadExames = true;
      this.forceRender();
    },

    listGalerias(item) {
      this.paciente = item;
      this.dialogGaleria = true;
      this.forceRender();
    },

    listFotosClassificadas(item) {
      this.paciente = item;
      this.dialogFotosClassificadas = true;
      this.forceRender();
    },

    close() {
      this.dialog = false;
      this.dialogGaleria = false;
      this.dialogFotosClassificadas = false;
      this.dialogAtestado = false;
      this.dialogEncaminhamento = false;
      this.dialogExames = false;
      this.dialogReceita = false;
      this.dialogReceitaCarbonada = false;
      this.dialogTermo = false;
      this.dialogArquivo = false;
      this.forceRender();
    },

    searchPetById() {
      console.log(this.searchId);
      this.options.page = 1;
      if (this.searchId > 1) {
        this.atualizarListaPacientes(true);
      } else {
        this.atualizarListaPacientes();
      }
    },
  },
};
</script>
<style scoped>
#examesPDF {
  text-align: center;
}

p {
  letter-spacing: 0.3px;
}

.break-w {
  word-wrap: break-word;
}

strong {
  letter-spacing: 0.5px !important;
}

#header,
#footer,
#main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  width: 12.5rem;
  margin-right: auto;
}
#header img {
  width: 1rem;
}
.header-receita {
  margin: 0 !important;
  padding: 0 !important;
}
#main {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 4px;
  height: 15.8rem;
  max-height: 17rem;
  /* width: 13vw; */
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  /* background-color: tomato; */
}
#footer {
  /* background-color: rebeccapurple; */
  margin-top: 0.1rem;
  margin-bottom: 0;
  margin-right: 0.1rem;
  height: 1.2rem;
}
#footer img {
  max-width: 12.5rem;
  max-height: 1.2rem;
}

.carbonada {
  font-size: 3px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* background-color: rgb(233, 255, 255); */
  margin-left: 6rem;
  padding-top: 0px;
}

.carbon {
  width: 100vw !important;
  font-size: 3px;
}

.carbon .body {
  min-height: 30vh !important;
}
.carbon .rodape {
  min-height: 70vh !important;
  position: static !important;
  bottom: 0 !important;
}

.carbon p,
.carbon hr {
  margin: 0 !important;
  padding: 0 !important;
}
.gap {
  gap: 1px;
}
.texto {
  padding: 1px;
  font-size: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.space {
  color: white;
}
.zeroEspaco {
  padding: 0 !important;
  margin: 0 !important;
}
.marg-2 {
  margin-left: 0.7rem !important;
  margin-right: 0.7rem !important;
}
.indent {
  text-indent: 5px;
}
.termo {
  font-size: 3.5px;
  color: black;
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw;
}
.color-black {
  color: black;
}
.rodape {
  position: fixed;
  bottom: 0;
}
.termo .small {
  line-height: 4px !important;
  letter-spacing: 0.2px !important;
  font-size: 3.2px;
}
.listaIntensReceita {
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  color: brown !important;
}
</style>
