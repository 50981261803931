<template>
  <div>
    <div class="d-flex align-items-center justify-center">
      <h3>
        Tutor:
        <span class="font-weight-regular"> {{ comanda.nome_cliente }} </span>
      </h3>
      <h3 class="ml-5">
        ID/Pet:
        <span class="font-weight-regular">
          {{ comanda.id_pet }} - {{ comanda.nome_pet }}
        </span>
      </h3>
    </div>
    <div class="p-lg-3 d-flex">
      <div class="row  align-center" style="gap: 2rem">
        <h4>
          Valor Total:
          <b class="font-weight-regular">{{
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(comanda.valor)
          }}</b>
        </h4>
        <h4>
          Valor Pago:
          <b class="font-weight-regular">{{
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(valorPagoComanda)
          }}</b>
        </h4>
      </div>
      <div class="d-flex justify-between align-items-center">
        <v-btn
          small
          class="mr-5"
          color="primary"
          dark
          rounded
          @click="getProtuarios()"
          >Prontuarios</v-btn
        >
        <v-btn
          small
          class="bg-verde"
          dark
          rounded
          @click="showDialogIncluirItem"
          >Novo Item</v-btn
        >
      </div>
    </div>

    <v-dialog
      v-model="dialogProtuarios"
      v-if="dialogProtuarios"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <prontuarios
        :consultas="prontuarios"
        :pacienteInfo="pacienteInfo"
        @close="dialogProtuarios = false"
      />
    </v-dialog>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      item-key="comanda_item_id"
      class="elevation-1"
    >
      <template v-slot:item.valor="{ item }">
        {{  parseFloat(item.valor).toLocaleString("pt-br", {
               style: "currency",
              currency: "BRL",
             }), }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          class="bg-verde mr-5"
          dark
          rounded
          @click="showDialogExecucao(item)"
          v-if="
            comanda.comandaEstaAberta &
              (item.status == 2) &
              (item.status_requisicao == 1) &
              (perfil.id == 56 || perfil.id == 1)
          "
          >Executar</v-btn
        >

        <v-icon
          color="error"
          @click="excluirItemComanda(item)"
          title="EXCLUIR"
          v-if="
            comanda.comandaEstaAberta &
              (item.status == 2) &
              (item.status_requisicao != 2) &
              (perfil.id == 56 || perfil.id == 1)
          "
        >
          mdi mdi-close-thick
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      scrollable
      v-model="dialogIncluirItem"
      v-if="dialogIncluirItem"
      max-width="600px"
    >
      <ModalIncluirItem
        @close="dialogIncluirItem = false"
        @carregarItensComanda="setComandaItens()"
        :comanda="comanda"
      />
    </v-dialog>
    <v-dialog v-model="dialogExecucao" v-if="dialogExecucao" max-width="600px">
      <v-card>
        <v-toolbar class="bg-verde mb-5">
          <h3 class="text-white">
            Executar Requisição
          </h3>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-autocomplete
            outlined
            label="Executante"
            :items="executantes"
            :rules="[rules.required]"
            v-model="executanteSelected"
            item-text="nome"
            item-value="id"
          ></v-autocomplete>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Data"
                type="date"
                outlined
                v-model="dataExecutar"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                cols="4"
                label="Hora"
                type="time"
                outlined
                v-model="horaExecutar"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-5">
          <div class="d-flex w-100 justify-end">
            <v-btn color="error" text @click="dialogExecucao = false"
              >Cancelar</v-btn
            >
            <v-btn class="bg-verde ml-2" dark @click="executarRequisicao"
              >Executar</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped></style>
<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ComandaService from "@/services/comanda_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import PetsService from "@/services/pets_service.js";
import AuditoriaService from "@/services/auditoria_service.js";
import ModalIncluirItem from "./modal_incluir_item_comanda_auditoria.vue";

export default {
  mixins: [Mixin, VuexSnackbarMixin],
  components: { Prontuarios, ModalIncluirItem },
  data: () => ({
    codAuth: null,
    dialogPagarComandas: false,
    disabledCount: 0,
    dialogClose: false,
    parcelas: 0,
    singleSelect: false,
    selected: [],
    sum: 0,
    observacao: "",
    dialogDetalhesComandas: false,
    isCard: false,
    isCreditCard: false,
    dialogCodigoAuth: false,
    comandasItens: [],
    loading: false,
    formasdePagamento: [],
    formaPagamentoSelecionada: {},
    headers: [
      {
        text: "Procedimento",
        align: "start",
        sortable: false,
        value: "procedimentoDescricao",
      },
      {
        text: "Tipo Procedimento",
        align: "start",
        sortable: false,
        value: "tipoProcedimentoDescricao",
      },
      {
        text: "Valor",
        align: "start",
        sortable: false,
        value: "valor",
      },
      {
        text: "Convênio",
        align: "start",
        sortable: false,
        value: "convenioDescricao",
      },
      {
        text: "Status",
        align: "start",
        sortable: false,
        value: "status_comanda",
      },
      { text: "Data criado", value: "dataCriado", sortable: false },
      { text: "", align: "center", value: "actions", sortable: false },
    ],
    desserts: [],
    comandaService: ComandaService.build(),
    dialogEstorno: false,
    tiposEstorno: [],
    tipoEstornoSelecionado: {},
    valorEstorno: null,
    valorTotalEstornado: null,
    observacoesEstorno: "",
    valorExcedido: false,
    bandeira: "",
    valorPagoComanda: null,
    loadingEstorno: false,
    unidade: {},
    usuario: {},
    valorEstornoRecibo: "",
    dataEstorno: "",
    itemsEstorno: [],
    valorCreditoCliente: "",
    itemsReciboEstorno: [],
    forma_devolucao: "",
    perfil: null,
    nome_cliente: "",
    dialogProtuarios: false,
    prontuarios: [],
    pacienteInfo: {},
    dialogExecucao: false,
    executantes: [],
    requisicaoSelected: {},
    executanteSelected: null,
    dataExecutar: "",
    horaExecutar: "",
    rules: {
      required: (value) => !!value || "Campo obrigatório",
    },
    services: {
      petsService: PetsService.build(),
      auditoriaService: AuditoriaService.build(),
    },
    dialogIncluirItem: false,
  }),
  props: {
    comanda: {
      type: Object,
      required: true,
    },
    tutor: {
      required: true,
    },
  },
  watch: {
    comanda() {
      this.comandasItens = [];
      this.setComandaItens();
    },

    dialogClose() {
      if (this.dialogClose === true) {
        this.dialogPagarComandas = false;
        this.$emit("close");
      }
    },

    selected() {
      //let sum = 0
      this.sum = 0;
      console.log(this.selected);
      for (let i = 0; i < this.selected.length; i++) {
        this.sum = this.sum + parseFloat(this.selected[i].valor);
      }
    },
    close() {
      this.dialogPagarComandas = false;
      this.$emit("close");
    },
    dialogCodigoAuth() {
      this.observacao = "";
      this.bandeira = "";
      this.bandeira = "";
      this.codAuth = null;
    },
    tipoEstornoSelecionado() {
      console.log(this.tipoEstornoSelecionado);
    },
  },
  async mounted() {
    this.perfil = JSON.parse(sessionStorage.vuex).perfil;
    console.log(this.comanda);
    await this.setComandaItens();
    this.getFormasPagamento();
    const self = this;
    this.desserts.map((item) => {
      if (item.disabled) self.disabledCount += 1;
    });
    this.comandasItens.map((item) => {
      if (item.status == 2) {
        this.formaPagamentoSelecionada = Object.assign(
          this.formaPagamentoSelecionada,
          { [item.comanda_item_id]: item.id_forma_pagamento }
        );
      }
    });
    this.unidade = JSON.parse(sessionStorage.getItem("vuex")).unidade;
    await this.getTiposEstorno();
    this.valoresEstornados();
  },
  created() {
    const self = this;
    this.desserts.map((item) => {
      if (item.disabled) self.disabledCount += 1;
    });
  },
  methods: {
    selectAllToggle(props) {
      if (
        this.selected.length !=
        this.comandasItens.length - this.disabledCount
      ) {
        this.selected = [];
        const self = this;
        props.items.forEach((item) => {
          if (!item.disabled) {
            self.selected.push(item);
          }
        });
      } else this.selected = [];
    },
    async setComandaItens() {
      this.loading = true;
      const response = await this.comandaService.auditoriaComandaItensByComandaId(
        this.comanda.comanda_id
      );
      this.desserts = await response.map((item) => {
        return {
          ...item,
          procedimentoDescricao: item.procedimento_descricao,
          tipoProcedimentoDescricao: item.tipo_procedimento_descricao,
          valor: item.valor,
          convenioDescricao: item.tipo_convenio_descricao,
          statusDescricao: item.status_descricao,
          isPago: item.status == 2,
          dataCriado: new Date(
            item.data_criada + " " + item.hora_criada
          ).toLocaleString("pt-BR"),
        };
      });
      this.valorPagoComanda = response
        .filter((item) => item.status == "2")
        .reduce((acc, cur) => {
          return acc + parseFloat(cur.valor);
        }, 0);
      this.getCreditoCliente();
      this.loading = false;
    },
    async getProtuarios() {
      await this.services.petsService
        .getPetById(this.comanda.id_pet)
        .then((resp) => (this.pacienteInfo = resp));

      await this.services.auditoriaService
        .getConsultasbyComandaId(this.comanda.comanda_id)
        .then((resp) => resp.json())
        .then(({ data }) => (this.prontuarios = data.prontuarios));

      this.dialogProtuarios = true;
    },
    excluirItemComanda(item) {
      this.loading = true;
      const responseFunction = {
        onSucess: (status) => (resp) => {
          if (status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "sucess",
            });
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "error",
            });
          }
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        },
        onEnd: () => {
          this.loading = false;
          this.setComandaItens();
        },
      };
      if (item.tipo_procedimento_id == 2) {
        this.services.auditoriaService.auditoriaExcluirConsultaComanda(
          responseFunction,
          item.id_requisicao_procedimento
        );
      } else {
        this.services.auditoriaService.auditoriaExcluirItemComanda(
          responseFunction,
          item.id_requisicao_procedimento
        );
      }
    },
    valueRule(value) {
      if (!value) {
        return "Campo Obrigatório";
      }
    },
    async showDialogExecucao(item) {
      this.requisicaoSelected = item;
      if (["4", "5"].includes(item.tipo_procedimento_id)) {
        await this.services.auditoriaService
          .getAssistentesbyRequisicaoId(item.id_requisicao_procedimento)
          .then((resp) => resp.json())
          .then((resp) => {
            this.executantes = resp.data.map((ass) => {
              return {
                nome: ass.nome,
                id: ass.assistente_id,
              };
            });
          });
      } else {
        console.log("médico");
      }

      this.dialogExecucao = true;
    },
    async executarRequisicao() {
      if (
        !this.executanteSelected ||
        !this.dataExecutar ||
        !this.horaExecutar
      ) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os dados corretamente",
          color: "error",
        });
        return;
      }

      const dataExecucaoProcedimento = new Date(this.dataExecutar.split("-"));
      const dataCriacaoComanda = new Date(
        this.comanda.data_criada.split("/").reverse()
      );
      const dataInvalida = dataCriacaoComanda > dataExecucaoProcedimento;

      if (dataInvalida) {
        this.$_ACTIONS_showSnackbarMessage({
          message:
            "A data selecionada não pode ser anterior à data de criação da comanda",
          color: "error",
        });
        return;
      }

      const detalhesExecucao = {
        data_exec: this.dataExecutar,
        hora_exec: this.horaExecutar + ":00",
        requisicao_id: this.requisicaoSelected.id_requisicao_procedimento,
        assistente_id: this.executanteSelected,
      };

      if (["4", "5"].includes(this.requisicaoSelected.tipo_procedimento_id)) {
        await this.services.auditoriaService
          .auditoriaExecutarRequisicao(detalhesExecucao)
          .then(async (resp) => {
            const response = await resp.json();
            return {
              data: response,
              status: resp.status,
            };
          })
          .then((resp) => {
            if (resp.status == 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: resp.data.message,
                color: "sucess",
              });
              this.setComandaItens();
              this.dialogExecucao = false;
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: resp.data.message,
                color: "error",
              });
            }
          })
          .catch(() => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          });
      } else {
        console.log("médico");
      }
    },
    showDialogIncluirItem() {
      this.dialogIncluirItem = true;
    },
  },
  computed: {
    estornarValorTotal() {
      return this.itemsEstorno.reduce((acc, cur) => acc + cur.valor, 0);
    },
  },
};
</script>
