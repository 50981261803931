const global = {
  api: {
    // Produção
    // Url Particular
    // baseURL: "https://api.anclivepa-pv.pettree.com.br/v1/public",
    // baseURL: "https://api.anclivepaonline.pettree.com.br/v1/public",
    // Url Publico
    baseURL: "https://api.anclivepa-pb.pettree.com.br/v1/public",
    // baseURL: "https://api.televetbrasil.pettree.com.br/v1/public",
    baseUrlChamefacil_api: "https://chamefacilpettreeapi2.prolins.com.br/api",
    baseUrlChamefacil: "https://chamefacilpettree.prolins.com.br/api",

    // Desenvolvimento
    // baseURL: "https://pettreeapi.pb.homolog.prolins.com.br/v1/public",
    // baseURL: "https://1b27-189-36-194-62.ngrok-free.app/anclivepa_pb_a/public",
    // baseUrlChamefacil: "http://chamefacilpettreeteste.prolins.com.br/api",
    // baseUrlChamefacil_api: "http://chamefacilapiteste.prolins.com.br/api",
  },
  consultas: {
    tempoMinimo: 1,
    idTipoProcedimentoConsulta: 5,
  },
  tiposProcedimentos: {
    //consulta: 5,
    //cirurgia: 6,
    consulta: 2,
    cirurgia: 3,
    compromissoPessoal: 7,
  },
  utils: {
    diasDaSemana: [
      { value: 0, label: "Domingo" },
      { value: 1, label: "Segunda-feira" },
      { value: 2, label: "Terça-feira" },
      { value: 3, label: "Quarta-feira" },
      { value: 4, label: "Quinta-feira" },
      { value: 5, label: "Sexta-feira" },
      { value: 6, label: "Sábado" },
    ],
  },
  profiles: [
    { id: 1, description: "admin" },
    { id: 2, description: "medico" },
    { id: 3, description: "assistente" },
    { id: 4, description: "paciente" },
    { id: 56, description: "recepcao" },
  ],
  snackbar: {
    colors: {
      sucess: "#3E682A",
      error: "error",
      warning: "#FFCA28",
    },
  },
  messages: {
    internalServerError:
      "Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.",
  },
};
export { global };
